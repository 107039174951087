function initMap() {
  //DO NOT RUN SCRIPT WHEN MAP NOT PRESENT
  if (document.getElementById("map-container") === null) return;

  jQuery(document).ready(function ($) {

    showLoader(".directory-results-list-inner");
    showLoader(".directory-results-map-inner");

    var bounds = new google.maps.LatLngBounds();
    var windows = [];
    var center;
    var map;
    var page = 1,
      maxPage = false,
      itemsPerPage = 6,
      salon_name = "",
      brand_filter = "";
    var markersArray = [];

    $("#location").val( search_location );

    //SET INITIAL HEIGHT OF THE MAP AREA
    $(".directory-results-map-inner").css({
      height: "100%",
      // "min-height": "40vw",
    });

    // Check for Load Location, or search by lat/lng
    var lat, lng, search_location;
    if( directory.Location.Coordinates ){
      ({ lat, lng, search_location } = directory.Location.Coordinates);
      setupMap();
    } 
    else {
      getBrowserLatLng().then((coords) => {
        if( coords ){
          lat = coords.latitude;
          lng = coords.longitude;
          search_location = "My Location";
        }
        else{
          lat = "43.6532";
          lng = "-79.3832";
          search_location = "Toronto, On";
        }
        setupMap();
        $("#location").val( search_location );
        $("#search-locations").click();
      });
    }

    //MAIN WORK FLOW FOR SEARCH CLICK.
    $("#search-locations").click(function (e) {
      // console.log("Search Clicked");
      e.preventDefault();
      showLoader(".directory-results-list-inner");
      showLoader(".directory-results-map-inner");

      //Set Default location if all form fields are empty and no salons initially listed, like on the initial page load (this should be replaced/removed when there's a proper GEO IP service available)
      let salons_listed = $(".listing-list .listing-list-item").length;
      // console.log(salons_listed)
      if (
        $("#brands").val().trim() == "" &&
        $("#salon").val().trim() == "" &&
        $("#location").val().trim() == "" &&
        salons_listed == 0
      ) {
        // search_location = "Cansis";
        // console.log(search_location);
      } else {
        // var search_radius =  $('#search-radius').val();
        search_location = $("#location").val().toUpperCase();
        salon_name = $("#salon").val();
        brand_filter = $("#brands").val();
      }

      // showLoader();

      page = 1;

      makeRequest();

      return false;
    });

    // Force click the search results on page load
    if( search_location ){
      $("#search-locations").click();
    }

    function setupMap(){
      // SETUP MAP STYLE AND PROPERTIES
      map = new google.maps.Map(document.getElementById("map-container"), {
       center: { lat: parseFloat(lat), lng: parseFloat(lng) },
       zoom: 16,
       maxZoom: 16,
       minZoom: 3,
       styles: [
         {
           featureType: "administrative",
           elementType: "geometry",
           stylers: [
             {
               visibility: "off",
             },
           ],
         },
         {
           featureType: "poi",
           stylers: [
             {
               visibility: "off",
             },
           ],
         },
         {
           featureType: "road",
           elementType: "labels.icon",
           stylers: [
             {
               visibility: "off",
             },
           ],
         },
         {
           featureType: "transit",
           stylers: [
             {
               visibility: "off",
             },
           ],
         },
       ],
     });
   }

    function makeRequest() {
      
      var data = {
        nonce: directory.simplisticsnonce,
        location: search_location,
        salon_name: salon_name,
        brand_filter: brand_filter,
        search_radius: "150",
        page: page,
        action: "find_close_locations",
      };

      // NEW CODE: Add lat and lng to the data object
      if( "MY LOCATION" === search_location ){
        // Send lat and lng
        data.location = "";
        data.lat = lat;
        data.lng = lng;
        console.log( lat, lng );
      }

      $.post(directory.ajax_url, data)
        .done(function (response) {
          clearList();
          let res = JSON.parse(response);
          if (res.success === true) {
            maxPage = Math.ceil(res.count / itemsPerPage);
            hideLoader();

            center = {
              lat: Number(res.map_location.lat),
              lng: Number(res.map_location.lng),
            };
            map.setCenter(center);
            listMaplocations(res.locations);
            displayMap(res.locations);
            listPagination(res.count);
            $('.form-right').slideDown();
          } else {
            maxPage = 0;
            listPagination(0);
            clearOverlays();
            showErrorMessage(directory.translations.no_directories);
            $('.form-right').slideUp();
          }
        })
        .fail(function (response) {
          console.error("Error getting response details");
        })
        .always(function (response) {
          hideLoader();

          // Check next and previous buttons, remove href if at either ends
          let activePage = $("li.page-item.active a").attr("data-pagination");
          let lastPage = $("li.page-item:nth-last-of-type(2) a").attr(
            "data-pagination"
          );
          if (activePage === lastPage) {
            $("li.page-item:last-of-type a").removeAttr("href");
          }
          if (activePage === "1") {
            $("li.page-item:first-of-type a").removeAttr("href");
          }
          // console.log("always", response);
        });
    }

    $(document).on("click.paginate", "#directory-pagination a", function (e) {
      e.preventDefault();
      let activePage = $("li.page-item.active a").attr("data-pagination");
      let lastPage = $("li.page-item:nth-last-of-type(2) a").attr(
        "data-pagination"
      );

      var p = $(this).attr("data-pagination");

      //Disable action when at either ends of the page list
      if (activePage === lastPage && p == "n") return;
      if (activePage === "1" && p == "p") return;

      if (p == "p" && page > 1) {
        page--;
      } else if (p == "n" && page < maxPage) {
        page++;
      } else {
        //console.log("p set", p);
        page = p;
      }
      showLoader();
      makeRequest();

      $("html, body").animate(
        {
          scrollTop: $(".directory-results-outer").offset().top - 100,
        },
        500
      );
      return false;
    });

    //MAP AND HELPER FUNCTIONS

    function displayMap(locations) {
      $ = jQuery;

      // map = new google.maps.Map(document.getElementById('map-container'), {
      //     center:  {lat: 56.130366, lng: -106.346771},
      //     zoom: 3,
      //     maxZoom: 16
      // });

      clearOverlays();
      bounds = new google.maps.LatLngBounds();

      // 1) PLACE MARKERS
      $.each(locations, function (key, location) {
        var latlng = {
          lat: Number(location.latitude),
          lng: Number(location.longitude),
        };
        var marker = new google.maps.Marker({
          position: latlng,
          map: map,
          animation: google.maps.Animation.DROP,
          icon: directory.theme + "/assets/img/map.svg",
          title: location.location_name,
        });

        markersArray.push(marker);

        // RESET MAP CENTER AND ZOOM UPON EACH NEW LOCATION/MARKER
        resetMap(Number(locations[0].latitude), Number(locations[0].longitude));
        // bounds.extend(new google.maps.LatLng( Number(location.latitude), Number(location.longitude)));
        bounds.extend({
          lat: Number(location.latitude),
          lng: Number(location.longitude),
        });

        // INFO PANEL
        var infoWindow = new google.maps.InfoWindow({
          content:
            '<div class="window directions-window-panel"><div class="dealer-title">' +
            location.name.toUpperCase() +
            "</div><div>" +
            location.address.toUpperCase() +
            "</div><div>" +
            location.city.toUpperCase() +
            ", " +
            location.state.toUpperCase() +
            " " +
            location.zip.toUpperCase() +
            '</div><div><a class="info-directions" href="http://maps.google.com/maps/place/' +
            location.latitude +
            "," +
            location.longitude +
            '">Get Directions</a></div></div>',
        });

        //Try to add a click event on the Locale in the listing to the correspnding Marker on the map

        // let listingSelector = "article[data-post='" + location.ID.toString() + "'] .locale";
        // let listingLocale = $(listingSelector);

        // console.log(location, listingSelector, listingLocale);

        // $(listingLocale).on('click', function() {
        //     closeWindows();
        //     this.infowindow.open(map, this);
        // });
        google.maps.event.addListener(marker, "click", function () {
          closeWindows();
          this.infowindow.open(map, this);
        });
        marker.infowindow = infoWindow;
        windows.push(infoWindow);
      });

      if (markersArray.length > 0) {
        map.fitBounds(bounds);
      }

      function calculateCenter2() {
        center = map.getCenter();
      }

      google.maps.event.addDomListener(map, "idle", function () {
        calculateCenter2();
      });
      google.maps.event.addDomListener(window, "resize", function () {
        // console.log('resizing');
        map.setCenter(center);
        if (markersArray.length > 0) {
          map.fitBounds(bounds);
        }
      });

      // google.maps.event.trigger(map, "resize");
      // setTimeout( (function() {
      //     google.maps.event.trigger(map, "resize");
      // }), 1000);
    }

    function clearOverlays() {
      for (var i = 0; i < markersArray.length; i++) {
        markersArray[i].setMap(null);
      }
      markersArray.length = 0;
    }

    // 5) MAP LISTINGS
    function listMaplocations(locations) {
      let resultsList = document.querySelector(
        ".directory-results-list-inner ul"
      );
      let listingTemplate = document.querySelector(
        ".directory-results-list-inner  template"
      ).content;

      // console.log(locations[0]);

      // {"id":"25788","latitude":"43.65174000","longitude":"-79.37299000","distance2":"0.8","permalink":"http://localhost/gcs_new/directory/worldsalon/","name":"worldSALON","address":"132 Adelaide St East","zip":"M5C 1L7","city":"Toronto","state":"ON","image":"http://localhost/gcs_new/wp-content/themes/gcs/img/fallbacks/Flowers.jpg"}
      $.each(locations, function (key, location) {
        let newItem = listingTemplate.cloneNode(true);
        let statecode = get_state_code(location.state);
        $(newItem).find("article").data("lat", location.latitude);
        $(newItem).find("article").data("lng", location.longitude);
        $(newItem).find("article").data("post", location.ID);
        $(newItem).find(".salon-name a").attr("href", location.permalink);
        $(newItem).find(".salon-name a").html(location.name);
        $(newItem).find(".city").text(location.city);
        $(newItem).find(".province").text(statecode);
        $(newItem).find("img").attr("src", location.image);

        // console.log($(newItem).find('article').data('lat'));

        resultsList.appendChild(newItem);
      });

      google.maps.event.trigger(map, "resize");
      // map.setCenter(center);
      if (markersArray.length > 0) {
        map.fitBounds(bounds);
      }
    }

    function listPagination(count) {
      //count = 72;
      if (maxPage == false) {
        $(".pagination-wrapper").html("");
        return;
      }
      page = parseInt(page);

      if (count > itemsPerPage) {
        var html = "";
        // console.log("page", page, maxPage);
        if (page >= 1) {
          html +=
            '<li class="page-item"><a class="page-link" data-pagination="p" href="#" title="Previous"><span class="sr-only">Previous</span><span class="fas fa-arrow-circle-left"></span></a></li>';
        }
        for (var i = 1; i <= maxPage; i++) {
          // lol pagination, we want to make sure we are building proper concatination
          // console.log(
          //   "i",
          //   i,
          //   i == page - 1,
          //   i == page + 1,
          //   i == 1,
          //   i == maxPage,
          //   i == page
          // );
          if (
            i == page - 1 ||
            i == page + 1 ||
            i == 1 ||
            i == maxPage ||
            i == page
          ) {
            if (i == maxPage && page < maxPage - 2) {
              html += '<li class="page-item">..</li>';
            }

            html +=
              '<li class="page-item' +
              (page == i ? " active" : "") +
              '"><a class="page-link" data-pagination="' +
              i +
              '" href="#">' +
              i +
              "</a></li>";

            if (i == 1 && page > 3) {
              html += '<li class="page-item">..</li>';
            }
          }
        }

        if (page <= Math.ceil(count / itemsPerPage)) {
          html +=
            '<li class="page-item"><a class="page-link" data-pagination="n" href="#" title="Next"><span class="sr-only">Next</span><span class="fas fa-arrow-circle-right"></span></a></li>';
        }

        if (Math.ceil(count / itemsPerPage) > 1) {
          $(".pagination-wrapper").html("");
          $(".pagination-wrapper").append(
            '<nav aria-label="Page navigation for directory listings">' +
              '<ul class="pagination" id="directory-pagination">' +
              html +
              "</ul>" +
              "</nav>"
          );
        }
      } else {
        // remove Pagination
        $(".pagination-wrapper").html("");
      }
    }

    function resetMap(lat, lng) {
      map.setCenter({ lat: Number(lat), lng: Number(lng) });
      map.setZoom(16);
    }

    function closeWindows() {
      if (windows.length == 0) {
        return;
      }
      console.log(windows);
      for (var i = 0; i < windows.length; i++) {
        windows[i].close();
      }
    }

    function clearList() {
      $(".listing-list").html("");
      $("#display-map-error, #display-map-feedback").remove();
    }

    function showErrorMessage(message) {
      let template = document
        .querySelector("#map-feedback")
        .content.cloneNode(true);
      $(template).find("#display-map-feedback").insertBefore(".listing-list");
      $("#display-map-feedback").text(message);
      $(".maps-feedback").show();
      google.maps.event.trigger(map, "resize");
    }

    function showLoader(target = null) {
      if (target == null) {
        target = ".directory-results-map-inner";
      }

      let template = document
        .querySelector("#map-feedback")
        .content.cloneNode(true);
      $(template).find(".loader-overlay").appendTo(target);
    }

    function hideLoader(callback = null) {
      $(".loader-overlay").css({ opacity: 0 });
      $("#map-container, .listing-list").css({ opacity: 1 });
      $(".loader-overlay").remove();
    }
    if( search_location ){
      $("#search-locations").click();
    }
  });
}

/**
 * Get a 2 letter code for the state/province
 * @param {String} state
 */
function get_state_code(state) {
  let original = state;

  state = state.trim();
  state = state.replace(/\s+/g, "");
  state = state.toLowerCase();

  switch (state) {
    // American States

    case "alabama":
      state = "AL";
      break;
    case "alaska":
      state = "AK";
      break;
    case "arkansas":
      state = "AR";
      break;
    case "arizona":
      state = "AZ";
      break;
    case "california":
      state = "CA";
      break;
    case "colorado":
      state = "CO";
      break;
    case "connecticut":
      state = "CT";
      break;
    case "delaware":
      state = "DE";
      break;
    case "florida":
      state = "FL";
      break;
    case "georgia":
      state = "GA";
      break;
    case "hawaii":
      state = "HI";
      break;
    case "idaho":
      state = "ID";
      break;
    case "illinois":
      state = "IL";
      break;
    case "indiana":
      state = "IN";
      break;
    case "iowa":
      state = "IA";
      break;
    case "kansas":
      state = "KS";
      break;
    case "kentucky":
      state = "KY";
      break;
    case "louisiana":
      state = "LA";
      break;
    case "maine":
      state = "ME";
      break;
    case "maryland":
      state = "MD";
      break;
    case "massachusetts":
      state = "MA";
      break;
    case "michigan":
      state = "MI";
      break;
    case "minnesota":
      state = "MN";
      break;
    case "mississippi":
      state = "MS";
      break;
    case "missouri":
      state = "MO";
      break;
    case "montana":
      state = "MT";
      break;
    case "nebraska":
      state = "NE";
      break;
    case "nevada":
      state = "NV";
      break;
    case "newhampshire":
      state = "NH";
      break;
    case "newjersey":
      state = "NJ";
      break;
    case "newmexico":
      state = "NM";
      break;
    case "newyork":
      state = "NY";
      break;
    case "northcarolina":
      state = "NC";
      break;
    case "northdakota":
      state = "ND";
      break;
    case "ohio":
      state = "OH";
      break;
    case "oklahoma":
      state = "OK";
      break;
    case "oregon":
      state = "OR";
      break;
    case "pennsylvania":
      state = "PA";
      break;
    case "rhodeisland":
      state = "RI";
      break;
    case "southcarolina":
      state = "SC";
      break;
    case "southdakota":
      state = "SD";
      break;
    case "tennessee":
      state = "TN";
      break;
    case "texas":
      state = "TX";
      break;
    case "utah":
      state = "UT";
      break;
    case "vermont":
      state = "VT";
      break;
    case "virginia":
      state = "VA";
      break;
    case "washington":
      state = "WA";
      break;
    case "westvirginia":
      state = "WV";
      break;
    case "wisconsin":
      state = "WI";
      break;
    case "wyoming":
      state = "WY";
      break;

    // Canadian Provinces
    case "alberta":
      state = "AB";
      break;
    case "britishcolumbia":
      state = "BC";
      break;
    case "manitoba":
      state = "MB";
      break;
    case "newbrunswick":
      state = "NB";
      break;
    case "newfoundlandandlabrador":
      state = "NL";
      break;
    case "northwestterritories":
      state = "NT";
      break;
    case "novascotia":
      state = "NS";
      break;
    case "nunavut":
      state = "NU";
      break;
    case "ontario":
      state = "ON";
      break;
    case "princeedwardisland":
      state = "PE";
      break;
    case "quebec":
      state = "QC";
      break;
    case "saskatchewan":
      state = "SK";
      break;
    case "yukonterritory":
    case "yukon":
      state = "YT";
      break;

    default:
      state = original;
  }

  return state;
}

function getBrowserLatLng(){
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          console.log( position );
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        function(error) {
          resolve(false);
        }
      );
    } else {
      resolve(false);
    }
  });
}